import {TermData} from "./models/TermData";
import {logDev, modTime} from "@ucm-it/purrfect-utilities";

const LS_SELECTED_TERM_ID = 'selected-term-id';
const LS_SELECTED_TERM_EXPIRATION = 'selected-term-expiration';

function isClientSide() {
    return typeof localStorage !== 'undefined';
}

export function clearSelectedTerm() {
    if (!isClientSide()) {
        return;
    }

    localStorage.removeItem(LS_SELECTED_TERM_ID);
    localStorage.removeItem(LS_SELECTED_TERM_EXPIRATION);

    logDev('Selected term cleared');
}

export function getSelectedTerm(): string | null {
    if (!isClientSide()) {
        return null;
    }

    if (localStorage.getItem(LS_SELECTED_TERM_ID) === null) {
        return null;
    }

    return localStorage.getItem(LS_SELECTED_TERM_ID);
}

export function saveSelectedTerm(term: TermData | null) {
    if (!isClientSide()) {
        return;
    }

    if (term === null) {
        return;
    }

    localStorage.setItem(LS_SELECTED_TERM_ID, term.termcode);
    localStorage.setItem(
        LS_SELECTED_TERM_EXPIRATION,
        modTime(new Date(), 30, 'minute').toISOString(),
    );

    logDev(`Selected term saved: ${term.termcode}`);
}
