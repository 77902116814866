import axios, {AxiosError, InternalAxiosRequestConfig} from 'axios';
import {reportErrorLevel} from "@ucm-it/purrfect-reporting";

const instance = axios.create({
	baseURL: process.env.NEXT_PUBLIC_BASEURL,
});

const csrfProtectedMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];

const isCsrfProtected = (config: InternalAxiosRequestConfig): boolean => {
	return csrfProtectedMethods.includes(config.method.toUpperCase());
};

const addCsrfTokenRequestInterceptor = async (
	config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
	if (!isCsrfProtected(config)) {
		return config;
	}

	const {
		data: { token, headerName },
	} = await instance.get('/csrf/token', { withCredentials: true });

	config.headers[headerName] = token;

	return config;
};

const reportFailedRequests = (error: AxiosError) => {
	reportErrorLevel(error, {
		response: error.response.data,
	});

	return Promise.reject(error);
};

instance.interceptors.request.use(
	addCsrfTokenRequestInterceptor,
	Promise.reject,
);

instance.interceptors.response.use((response) => response, reportFailedRequests);

export default instance;
