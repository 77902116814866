import { reportErrorLevel } from '@ucm-it/purrfect-reporting';
import { useEffect, useState } from 'react';

import { fetchAllApplications } from '../api/applications';
import { getSelectedTerm } from '../src/localStorage';
import {
	MockAcceptedApplication,
	MockActiveStudent,
	MockCanceledApplication,
	MockDelayedApplication,
	MockDeniedApplication,
	MockDualApplication,
	MockPendingApplication,
	MockWaitlistApplication,
	MockWelcome,
} from '../src/mocks';
import { ApplicationTerms, TermData } from '../src/models/TermData';
import { useDebugMode } from './useDebugMode';

export enum MockEntry {
	StudentAcceptances = 'StudentAcceptances',
	StudentActive = 'StudentActive',
	StudentDelayed = 'StudentDelayed',
	StudentDenied = 'StudentDenied',
	StudentCancellations = 'StudentCancellations',
	StudentPending = 'StudentPending',
	StudentRejections = 'StudentRejections',
	StudentWaitlist = 'StudentWaitlist',
	StudentWelcome = 'StudentWelcome',
}

function getMockValue(string: '' | keyof typeof MockEntry) {
	switch (string) {
		default:
		case MockEntry.StudentAcceptances:
			return MockAcceptedApplication;

		case MockEntry.StudentActive:
			return MockActiveStudent;

		case MockEntry.StudentCancellations:
			return MockCanceledApplication;

		case MockEntry.StudentDelayed:
			return MockDelayedApplication;

		case MockEntry.StudentDenied:
			return MockDeniedApplication;

		case MockEntry.StudentPending:
			return MockPendingApplication;

		case MockEntry.StudentWaitlist:
			return MockWaitlistApplication;

		case MockEntry.StudentWelcome:
			return MockWelcome;

		case MockEntry.StudentRejections:
			return MockDualApplication;
	}
}

export function useLiveApplications(setter: (term: TermData) => void) {
	const { enabled, query } = useDebugMode();
	const [isReady, setIsReady] = useState(false);
	const [hasNoValidApplications, setHasNoValidApplications] = useState(false);
	const [applications, setApplications] = useState<TermData[] | null>(null);

	useEffect(() => {
		setIsReady(false);

		if (enabled) {
			const mockName = query.DEBUG_MODE;

			if (!(mockName in MockEntry)) {
				throw new Error(`Invalid application mock: ${mockName}`);
			}

			setApplications(getMockValue(mockName as keyof typeof MockEntry).terms);

			return;
		}

		fetchAllApplications()
			.then((apps) => {
				setApplications(apps.terms);
			})
			.catch(reportErrorLevel);
	}, []);

	useEffect(() => {
		if (applications == null) {
			return;
		}

		// If the backend returns no applications at all OR it returns a single
		// Application, but it leads to "noApplications," don't show the back button.

		const emptyApplications = applications.length === 0;
		const noApplicationsFound =
			applications.length === 1 && applications[0].page === 'noApplications';

		if (emptyApplications || noApplicationsFound) {
			setHasNoValidApplications(true);
		} else if (applications.length === 1) {
			setter(applications[0]);
		} else {
			const lsTerm = getSelectedTerm();

			if (lsTerm) {
				const term = applications.find((term) => term.termcode === lsTerm);

				if (term) {
					setter(term);
				}
			}
		}

		setIsReady(true);
	}, [applications]);

	return {
		applications,
		hasNoValidApplications,
		isReady,
	};
}
